import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Link,
  Snackbar,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { usePostHog } from 'posthog-js/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import * as Yup from 'yup';
import { BACKEND_API_URL } from '../../config-global';
import { useResponsive } from '../../hooks/use-responsive';
import FormProvider, { RHFTextField } from '../hook-form';

WaitlistSnackbar.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};
function WaitlistSnackbar({ open, handleClose }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000} // Snackbar will auto-close after 5000ms (5 seconds)
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%', marginTop: 8 }}>
        Thank you for signing up to the waitlist!
      </Alert>
    </Snackbar>
  );
}

// const FormBackground = styled('div')(({ theme, burger }) => ({
//   backdropFilter: burger ? 'blur(12px)' : 'none', // Increase the blur radius slightly
//   backgroundColor: burger ? alpha(theme.palette.common.white, 1) : 'transparent', // Increase the alpha for more opacity
//   borderRadius: theme.shape.borderRadius,
//   padding: theme.spacing(2),
//   // You might want to add a border or shadow if it suits your design
//   border: burger ? `1px solid ${alpha(theme.palette.common.white, 0.3)}` : 'none',
//   boxShadow: burger ? `0 4px 8px ${alpha(theme.palette.common.black, 0.2)}` : 'none',
// }));

MailingForm.propTypes = {
  burger: PropTypes.bool,
};
export function MailingForm({ burger = false }) {
  const posthog = usePostHog();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const isDesktop = useResponsive('up', 'md');
  const Schema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email('Email must be a valid email address')
      .required('Email is required')
      .max(200),
    agreeToPrivacyPolicy: Yup.boolean()
      .required('You must agree to the privacy policy')
      .oneOf([true], 'You must agree to the privacy policy'), // Validation for the checkbox
  });
  const methods = useForm({
    resolver: yupResolver(Schema),
  });
  const {
    setError,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = methods;

  const onSubmit = async (data) => {
    try {
      await axios.post(`${BACKEND_API_URL}contact/waitlist`, data);
      posthog?.identify(data.email, {
        email: data.email,
      });
      posthog?.capture('waitlist_entry');
      setShowSnackbar(true);
      reset();
    } catch (error) {
      console.error(error);
      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbar(false);
  };

  // if burger set mx to 1 else omit
  const sx = {};
  if (burger) {
    sx.mx = 1;
  }

  return (
    <>
      <WaitlistSnackbar open={showSnackbar} handleClose={handleClose} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          {!burger && !!isDesktop ? (
            <Stack direction="row" spacing={1}>
              <RHFTextField name="email" label="Email" />

              <Button type="submit" size="large" variant="contained" height="100">
                Submit
              </Button>
            </Stack>
          ) : (
            <>
              <RHFTextField name="email" label="Email" />
              <Button type="submit" size="large" variant="contained" height="100">
                Submit
              </Button>
            </>
          )}
          <Controller
            name="agreeToPrivacyPolicy"
            control={control}
            render={({ field }) => (
              <>
                <FormControlLabel
                  control={<Checkbox {...field} color="primary" />}
                  label={
                    <span>
                      I read and agree with the{' '}
                      <Link
                        component={RouterLink}
                        to={paths.privacy}
                        underline="always"
                        color="text.primary"
                      >
                        Privacy Policy.
                      </Link>
                    </span>
                  }
                />
                {errors.agreeToPrivacyPolicy && (
                  <Typography color="error" variant="caption">
                    {errors.agreeToPrivacyPolicy.message}
                  </Typography>
                )}
              </>
            )}
          />
        </Stack>
      </FormProvider>
    </>
  );
}

export default function MaillistPreview() {
  const theme = useTheme();

  return (
    <Card>
      <CardHeader
        title="Join the Waitlist for Early Access" // "Get Early Access to Digital Truth:"
        titleTypographyProps={{ color: theme.palette.primary.main }}
      />
      <CardContent>
        <MailingForm />
      </CardContent>
    </Card>
  );
}
