import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { Box, Card, CardContent, Container, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { m } from 'framer-motion';
import React from 'react';
import { bgGradient } from '../../theme/css';
import { MotionViewport, varFade } from '../animate';
import { MailingForm } from './MaillistPreview';

export default function HomeAdvertisement() {
  const theme = useTheme();

  const renderDescription = (
    <Box
      sx={{
        textAlign: {
          xs: 'center',
          md: 'left',
        },
      }}
    >
      <Box
        component={m.div}
        variants={varFade().inDown}
        sx={{ color: 'common.white', mb: 5, typography: 'h2' }}
      >
        Want to Expose Deepfakes?
      </Box>
      <Box
        component={m.div}
        variants={varFade().inDown}
        sx={{ color: 'common.white', mb: 1, typography: 'h2' }}
      >
        <Card sx={{ mx: 1 }}>
          <CardContent>
            <MailingForm burger />
          </CardContent>
        </Card>
      </Box>
    </Box>
  );

  const renderImg = (
    <Box
      component={m.div} // Changed from m.img to m.div
      animate={{
        y: [-20, 0, -20],
      }}
      transition={{ duration: 4, repeat: Infinity }}
      sx={{
        height: { xs: 240, md: 420 },
        width: { xs: 220, md: 420 },
        display: 'flex',
        color: 'common.white',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <RocketLaunchIcon sx={{ fontSize: { xs: '13rem', md: '20rem' } }} />
    </Box>
  );

  return (
    <Container component={MotionViewport} sx={{ px: 0 }}>
      <Stack
        alignItems="center"
        justifyContent="center"
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          ...bgGradient({
            direction: '135deg',
            startColor: theme.palette.primary.main,
            endColor: theme.palette.primary.dark,
          }),
          borderRadius: 2,
        }}
      >
        {renderImg}

        {renderDescription}
      </Stack>
    </Container>
  );
}
