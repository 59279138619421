import { Grid } from '@mui/material';
import React from 'react';
import HomeAdvertisement from '../../components/conversion/HomeAdvertisement';
import MorganFreeman from './landingPage/MorganFreeman';
import Partners from './landingPage/Partners';
import WhatMakesUsSpecial from './landingPage/WhatMakesUsSpecial';

// ----------------------------------------------------------------------

export default function LandingPage() {
  return (
    <Grid container spacing={20}>
      <Grid item>
        <MorganFreeman />
      </Grid>

      <Grid item>
        <WhatMakesUsSpecial />
      </Grid>

      <Grid item>
        <Partners />
      </Grid>

      <Grid item xs={12}>
        <HomeAdvertisement />
      </Grid>
    </Grid>
  );
}
