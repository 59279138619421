import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
// utils
import qs from 'qs';
import axios, { endpoints } from 'src/utils/axios';
//
import { usePostHog } from 'posthog-js/react';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const posthog = usePostHog();

  const getMe = async () => {
    const response = await axios.get(endpoints.auth.me);
    const user = response.data;
    user.displayName = `${user.first_name} ${user.last_name}`;
    if (!user.is_verified) {
      throw new Error('User is not verified');
    }
    return user;
  };

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const user = await getMe();

        dispatch({
          type: 'INITIAL',
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN 
  const login = useCallback(async (email, password) => {
    const response = await axios.post(
      endpoints.auth.login,
      qs.stringify({
        username: email,
        password,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    posthog?.identify(email, {
      email: email,
    });
    posthog?.capture('user_login', { login_method: 'email' });

    const { access_token } = response.data;

    setSession(access_token);
    const user = await getMe();

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
    // ignore eslint warnings because of posthog dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REGISTER
  const register = useCallback(
    async (email, password, firstName, lastName) => {
      const data = {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      };

      await axios.post(endpoints.auth.register, data);
      posthog?.identify(email, {
        email: email,
      });
      posthog?.capture('user_register');
      await login(email, password);
      // const { accessToken, user } = response.data;
      //
      // localStorage.setItem(STORAGE_KEY, accessToken);
      //
      // dispatch({
      //   type: 'REGISTER',
      //   payload: {
      //     user,
      //   },
      // });
    },
    // ignore eslint warnings because of posthog dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [login]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      logout,
    }),
    [login, logout, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
