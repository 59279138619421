import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Container, Divider, Grid, IconButton, Link, Stack, Typography } from '@mui/material';
// routes
import { paths } from '../../routes/paths';
// components
import React from 'react';
import Iconify from '../../components/iconify';
import Logo from '../../components/logo';

// ----------------------------------------------------------------------

const _socials = [
  {
    value: 'linkedin',
    name: 'Linkedin',
    icon: 'eva:linkedin-fill',
    color: '#007EBB',
    path: 'https://www.linkedin.com/company/detesia/',
  },
];

const LINKS = [
  {
    headline: 'Detesia',
    children: [
      { name: 'Upload', href: paths.dashboard.upload },
      { name: 'About', href: paths.about },],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Imprint', href: paths.impressum },
      { name: 'Privacy Policy', href: paths.privacy },
      { name: 'Terms of Service', href: paths.termsOfService },
    ],
  },
  {
    headline: 'Contact',

    children: [
      { name: 'Contact us', href: paths.contact },
      { name: 'contact@detesia.com', href: 'mailto:contact@detesia.com', external: true },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {

  // eslint-disable-next-line no-unused-vars
  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
        pb: 3, // bottom padding of 3
      }}
    >
      <Divider />

      <Container sx={{ pt: 5 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              Pioneering deepfake solutions responsibly.
            </Typography>

            <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 2 },
              }}
            >
              {_socials.map((social) => (
                <Link
                  key={social.name}
                  href={social.path}
                  color="inherit"
                  variant="body2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <IconButton>
                    <Iconify icon={social.icon} />
                  </IconButton>
                </Link>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              justifyContent="space-between"
              direction={{ xs: 'column', md: 'row' }}
            >
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) =>
                    link?.external ? (
                      <Link key={link.name} href={link.href} color="inherit" variant="body2">
                        {link.name}
                      </Link>
                    ) : (
                      <Link
                        key={link.name}
                        to={link.href}
                        component={RouterLink}
                        color="inherit"
                        variant="body2"
                      >
                        {link.name}
                      </Link>
                    )
                  )}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  return mainFooter;
}
