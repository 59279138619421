import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid, Typography } from '@mui/material';
import { m } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { varFade } from '../../../components/animate';
import MaillistPreview from '../../../components/conversion/MaillistPreview';
import Image from '../../../components/image';
import useResponsive from '../../../hooks/useResponsive';

const landingTitle = 'Detect AI-Generated Images, Videos, and Audio'; // 'Reveal Deepfakes: Safeguard Your Digital Integrity';
const landingDescription = `Spot the unreal with our AI—this isn't the celebrity Morgan Freeman but a deepfake. Navigate the digital world with confidence using our cutting-edge detection tools. Stay one step ahead of digital imposters.`;
export default function MorganFreeman() {
  const isDesktop = useResponsive('up', 'md');

  const [isFading, setFading] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  const fadingOut = useCallback(() => {
    const { scrollY } = window;
    const threshold = 50; // Adjust this value as needed

    if (scrollY > threshold && isFading) {
      setFading(false);
    } else if (scrollY <= threshold && !isFading) {
      setFading(true);
    }
  }, [isFading]);

  useEffect(() => {
    window.addEventListener('scroll', fadingOut);

    return () => {
      window.removeEventListener('scroll', fadingOut);
    };
  }, [isFading, fadingOut]);

  const handleScrollImage = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollImage);
    return () => {
      window.removeEventListener('scroll', handleScrollImage);
    };
  }, []);

  const desktopView = (
    <Grid container spacing={5} sx={{ height: '100%', width: '100%' }}>
      <Grid
        item
        sm={12}
        md={6}
        sx={{
          pr: { md: 3 },
          position: 'sticky',
          top: 0,
        }}
        style={{
          paddingTop: '100px',
        }}
      >
        <SmoothImageTransition scrollPosition={scrollPosition} />
      </Grid>

      <Grid
        item
        sm={12}
        md={6}
        lg={6}
        style={{
          paddingTop: '100px',
        }}
      >
        <m.div variants={varFade().inRight} style={{ height: '100%' }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 'bold',
              background: (theme) =>
                `-webkit-linear-gradient(260deg, ${theme.palette.primary.main} 0%, ${theme.palette.grey[800]} 100%)`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {landingTitle}
          </Typography>
          <Typography sx={{ paddingTop: '12px' }}>{landingDescription}</Typography>
          <br />
          <MaillistPreview />
        </m.div>
      </Grid>

      <Grid
        item
        alignItems="center"
        justifyContent="center"
        lg={12}
        md={12}
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: isFading ? 1 : 0,
          transition: 'opacity 1s',
        }}
      >
        <Typography variant="h6" sx={{ color: 'grey.500', marginBottom: -5, marginTop: 10 }}>
          {' '}
          Scroll down to learn more{' '}
        </Typography>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          opacity: isFading ? 1 : 0,
          transition: 'opacity 1s',
        }}
      >
        <ExpandMoreIcon sx={{ fontSize: '4rem', color: 'lightgrey' }} />
      </Grid>

      {/* for correct spacing */}
      <Grid item sm={12} md={6} sx={{ pr: { md: 3 } }} />

      <Grid
        item
        sm={12}
        md={6}
        lg={6}
        sx={{ opacity: useTextAnimation(300) ? 1 : 0, transition: 'opacity 1.5s' }}
        style={{ paddingBottom: '250px' }}
      >
        <Typography
          variant="h2"
          sx={{
            marginY: 'auto',
            fontWeight: 'bold',
            background: (theme) =>
              `-webkit-linear-gradient(260deg, ${theme.palette.primary.main} 0%, ${theme.palette.grey[800]} 100%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {' '}
          Explainable AI{' '}
        </Typography>
        <Typography sx={{ paddingTop: '12px' }}>
          We show you why our models think your uploaded media is a deepfake. <br />
          It is becoming increasingly difficult to tell what is real and what is fake. This way, you
          can understand the model&apos;s decision and form your own opinion.
        </Typography>
      </Grid>
    </Grid>
  );

  const mobileView = (
    <Grid container spacing={5} alignItems="center" justify="center">
      <Grid item xs={12}>
        <Image src="/assets/images/home/morgan_freeman.webp" alt="Morgan Freeman Deepfake" />
      </Grid>

      <Grid item>
        <m.div variants={varFade().inRight} style={{ height: '100%' }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 'bold',
              background: (theme) =>
                `-webkit-linear-gradient(260deg, ${theme.palette.primary.main} 0%, ${theme.palette.grey[800]} 100%)`,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textAlign: 'center',
            }}
          >
            {landingTitle}
          </Typography>
          <Typography sx={{ paddingTop: '12px', textAlign: 'center' }}>
            {landingDescription}
          </Typography>
          <br />
          <MaillistPreview />
        </m.div>
      </Grid>

      <Grid
        item
        xs={12}
        alignItems="center"
        justifyContent="center"
        style={{ paddingTop: '100px' }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: 'bold',
            background: (theme) =>
              `-webkit-linear-gradient(260deg, ${theme.palette.primary.main} 0%, ${theme.palette.grey[800]} 100%)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textAlign: 'center',
          }}
        >
          {' '}
          Explainable AI{' '}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Image
          src="/assets/images/home/morgan_freeman_augmented.webp"
          alt="Morgan Freeman Augmented"
        />
      </Grid>

      <Grid item xs={12}>
        <Typography sx={{ textAlign: 'center' }}>
          We show you why our models think your uploaded media is a deepfake. <br />
          It is becoming increasingly difficult to tell what is real and what is fake. This way, you
          can understand the model&apos;s decision and form your own opinion.
        </Typography>
      </Grid>
    </Grid>
  );

  return <>{isDesktop ? desktopView : mobileView}</>;
}

SmoothImageTransition.propTypes = {
  scrollPosition: PropTypes.number.isRequired,
};
function SmoothImageTransition({ scrollPosition }) {
  const transitionStartOne = 600; // Adjust this threshold as needed; when morgan freeman changes

  return (
    <div style={{ position: 'relative' }}>
      {/* Base Image */}
      <img
        src="/assets/images/home/morgan_freeman.webp"
        alt="Morgan Freeman Deepfake"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 10,
          objectFit: 'cover',
        }}
      />

      {/* Transitioning Image */}
      <img
        src="/assets/images/home/morgan_freeman_augmented.webp"
        alt="Morgan Freeman"
        style={{
          opacity: scrollPosition / transitionStartOne,
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 20,
          objectFit: 'cover',
        }}
      />
    </div>
  );
}

function useTextAnimation(threshold) {
  const [isAnimating, setAnimating] = useState(false);

  // useCallback will return a memoized version of the callback that only changes if one of the dependencies has changed.
  const handleScrollText = useCallback(() => {
    const { scrollY } = window;

    if (scrollY > threshold && !isAnimating) {
      setAnimating(true);
    } else if (scrollY <= threshold && isAnimating) {
      setAnimating(false);
    }
  }, [threshold, isAnimating]);

  useEffect(() => {
    // We attach the scroll event listener on mount
    window.addEventListener('scroll', handleScrollText);

    // And remove it on unmount
    return () => {
      window.removeEventListener('scroll', handleScrollText);
    };
  }, [handleScrollText]); // handleScrollText is now stable and won't cause the effect to re-run

  return isAnimating;
}
