import { Box } from '@mui/material';
import PropTypes from 'prop-types';

// ----------------------------------------------------------------------

SingleFilePreview.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default function SingleFilePreview({ file }) {
  if (!file) {
    return null;
  }

  const imgUrl = typeof file === 'string' ? file : file.preview;

  return file.type.startsWith("image/") ?
    (
      <Box
        component="img"
        src={file.preview} alt={file.name}
        sx={{ objectFit: "contain", width: "100%", height: "100%" }}
      />
    )
    : (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video controls style={{ maxHeight: 'calc(100% - 16px)', maxWidth: 'calc(100% - 16px)' }}>
        <source src={imgUrl} type="video/mp4" />
        Sorry, your browser doesn&apos;t support embedded videos.
      </video>
    );
}
