import { Outlet } from 'react-router-dom';
// layouts
import React from 'react';
import MainLayout from '../../layouts/main';
import AboutUs from '../../pages/AboutUs';
import ContactPage from '../../pages/ContactPage';
import HomePage from '../../pages/HomePage';
import LegalNotice from '../../pages/legal-notice';
import PricavyPolicy from '../../pages/privacy-policy';
import TermsOfService from '../../pages/terms-of-service';
import { paths } from '../paths';

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [
      { path: '/', element: <HomePage /> },
      {
        path: paths.impressum,
        element: <LegalNotice />,
      },
      { path: paths.privacy, element: <PricavyPolicy /> },
      { path: paths.termsOfService, element: <TermsOfService /> },
      { path: paths.contact, element: <ContactPage /> },
      { path: paths.about, element: <AboutUs /> }
    ],
  },
];
